<template>
  <div class="flex flex-col h-full">
    <div class="py-5 bg-navbar border-b border-100">
      <div class="flex justify-between max-w-7xl w-full mx-auto px-5">
        <router-link :to="{ name: 'home' }">
          <logo-dark v-if="isDark" class="w-20" />
          <logo v-else class="w-20" />
        </router-link>

        <button @click="toggleDark">
          <moon-icon v-show="isDark" class="w-5 h-5" />
          <sun-icon v-show="!isDark" class="w-5 h-5" />
        </button>
      </div>
    </div>

    <div class="w-full px-5 md:px-10 max-w-7xl mx-auto pt-5 sm:pt-10 md:pt-20">
      <router-view class="flex-grow" />
    </div>
  </div>
</template>

<script>
import { useDark, useToggle } from '@vueuse/core';
import { SunIcon, MoonIcon } from '@heroicons/vue/solid';
import logo from '@/components/logo';
import logoDark from '@/components/logo-dark';

export default {
  name: 'App',
  components: { SunIcon, MoonIcon, logo, logoDark },
  setup() {
    const isDark = useDark();
    const toggleDark = useToggle(isDark);
    return {
      isDark,
      toggleDark,
    };
  },
};
</script>

<style>
#app {
  @apply w-full h-full;
}
</style>
