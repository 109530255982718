import { createWebHistory, createRouter } from 'vue-router';

const routes = [
  {
    path: '',
    component: () => import('@/pages/home'),
    name: 'home',
  },
  {
    path: '/demo',
    component: () => import('@/pages/demo'),
    name: 'demo',
  },
  {
    path: '/components/:category/:section/:element',
    component: () => import('@/pages/components'),
    name: 'components',
  },
];

export default createRouter({
  history: createWebHistory(),
  routes,
});
