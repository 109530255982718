<template>
  <svg
    height="100%"
    style="
      fill-rule: nonzero;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-linejoin: round;
    "
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="100%"
    xmlns:vectornator="http://vectornator.io"
    version="1.1"
    viewBox="0 0 751.275 240.975"
  >
    <defs />
    <g id="Base" vectornator:layerName="Base" visibility="hidden">
      <path
        d="M93.3185+160.739L93.3185+293.747C93.3185+309.372+96.2482+322.36+102.108+332.711C110.799+348.336+125.447+356.149+146.053+356.149C170.76+356.149+187.557+347.702+196.444+330.807C201.229+321.627+203.621+309.274+203.621+293.747L203.621+258.739L233.211+258.739L233.211+281.588C233.211+308.053+229.647+328.415+222.518+342.672C209.432+368.649+184.725+381.637+148.397+381.637C112.069+381.637+87.4103+368.649+74.422+342.672C67.2931+328.415+63.7287+308.053+63.7287+281.588L63.7287+160.739L93.3185+160.739Z"
        opacity="1"
        fill="#14d2d5"
      />
      <path
        d="M275.545+160.739L301.912+160.739L301.912+375.924L275.545+375.924L275.545+160.739Z"
        opacity="1"
        fill="#14d2d5"
      />
      <path
        d="M361.678+334.176C361.678+341.793+364.461+347.799+370.027+352.194C375.594+356.588+382.186+358.786+389.803+358.786C399.08+358.786+408.065+356.637+416.756+352.34C431.404+345.211+438.729+333.542+438.729+317.331L438.729+296.09C435.506+298.141+431.356+299.85+426.277+301.217C421.199+302.584+416.219+303.561+411.336+304.147L395.369+306.198C385.799+307.467+378.621+309.469+373.836+312.204C365.731+316.793+361.678+324.118+361.678+334.176ZM425.545+280.856C431.6+280.075+435.652+277.536+437.703+273.239C438.875+270.895+439.461+267.526+439.461+263.131C439.461+254.147+436.263+247.628+429.866+243.576C423.47+239.523+414.315+237.497+402.401+237.497C388.631+237.497+378.865+241.208+373.104+248.629C369.881+252.731+367.781+258.834+366.805+266.94L342.195+266.94C342.684+247.604+348.958+234.152+361.019+226.584C373.079+219.015+387.069+215.231+402.986+215.231C421.444+215.231+436.434+218.747+447.957+225.778C459.383+232.809+465.096+243.747+465.096+258.59L465.096+348.971C465.096+351.706+465.657+353.903+466.78+355.563C467.903+357.223+470.272+358.053+473.885+358.053C475.057+358.053+476.375+357.98+477.84+357.834C479.305+357.687+480.867+357.467+482.527+357.174L482.527+376.657C478.426+377.829+475.301+378.561+473.152+378.854C471.004+379.147+468.074+379.293+464.363+379.293C455.281+379.293+448.69+376.071+444.588+369.626C442.44+366.208+440.926+361.374+440.047+355.124C434.676+362.155+426.961+368.258+416.902+373.434C406.844+378.61+395.76+381.198+383.651+381.198C369.1+381.198+357.21+376.779+347.982+367.941C338.753+359.103+334.139+348.043+334.139+334.762C334.139+320.211+338.68+308.932+347.762+300.924C356.844+292.917+368.758+287.985+383.504+286.129L425.545+280.856Z"
        opacity="1"
        fill="#14d2d5"
      />
      <path
        d="M506.258+160.006L531.893+160.006L531.893+238.083C537.654+230.563+544.539+224.826+552.547+220.871C560.555+216.916+569.246+214.938+578.621+214.938C598.152+214.938+613.997+221.652+626.155+235.08C638.314+248.507+644.393+268.307+644.393+294.479C644.393+319.284+638.387+339.889+626.375+356.295C614.363+372.702+597.713+380.905+576.424+380.905C564.51+380.905+554.451+378.024+546.248+372.262C541.365+368.844+536.141+363.376+530.574+355.856L530.574+375.924L506.258+375.924L506.258+160.006ZM574.813+357.614C589.07+357.614+599.739+351.95+606.819+340.622C613.9+329.293+617.44+314.352+617.44+295.797C617.44+279.293+613.9+265.622+606.819+254.782C599.739+243.942+589.315+238.522+575.545+238.522C563.533+238.522+553.011+242.965+543.978+251.852C534.944+260.739+530.428+275.387+530.428+295.797C530.428+310.543+532.283+322.506+535.994+331.686C542.928+348.971+555.867+357.614+574.813+357.614Z"
        opacity="1"
        fill="#14d2d5"
      />
      <path
        d="M690.828+326.706C691.61+335.495+693.807+342.233+697.42+346.92C704.061+355.417+715.584+359.665+731.99+359.665C741.756+359.665+750.35+357.541+757.772+353.292C765.194+349.044+768.904+342.477+768.904+333.59C768.904+326.852+765.926+321.725+759.969+318.209C756.16+316.061+748.641+313.571+737.41+310.739L716.463+305.465C703.084+302.145+693.221+298.434+686.873+294.333C675.545+287.204+669.881+277.34+669.881+264.743C669.881+249.899+675.228+237.887+685.921+228.708C696.614+219.528+710.994+214.938+729.061+214.938C752.694+214.938+769.735+221.872+780.184+235.739C786.727+244.528+789.901+254.001+789.705+264.157L764.803+264.157C764.315+258.2+762.215+252.78+758.504+247.897C752.449+240.963+741.951+237.497+727.01+237.497C717.049+237.497+709.505+239.401+704.378+243.209C699.251+247.018+696.688+252.047+696.688+258.297C696.688+265.133+700.057+270.602+706.795+274.704C710.701+277.145+716.463+279.293+724.08+281.149L741.512+285.397C760.457+289.987+773.152+294.43+779.598+298.727C789.852+305.465+794.979+316.061+794.979+330.514C794.979+344.479+789.681+356.54+779.085+366.696C768.489+376.852+752.352+381.93+730.672+381.93C707.332+381.93+690.804+376.632+681.087+366.037C671.37+355.441+666.17+342.331+665.486+326.706L690.828+326.706Z"
        opacity="1"
        fill="#14d2d5"
      />
      <path
        d="M202.595+221.647C202.595+213.427+209.259+206.763+217.479+206.763C225.699+206.763+232.363+213.427+232.363+221.647C232.363+229.867+225.699+236.53+217.479+236.53C209.259+236.53+202.595+229.867+202.595+221.647Z"
        opacity="1"
        fill="#000000"
      />
    </g>
    <g id="Light" vectornator:layerName="Light" visibility="hidden">
      <path
        d="M93.3185+160.739L93.3185+293.747C93.3185+309.372+96.2482+322.36+102.108+332.711C110.799+348.336+125.447+356.149+146.053+356.149C170.76+356.149+187.557+347.702+196.444+330.807C201.229+321.627+203.621+309.274+203.621+293.747L203.621+258.739L233.211+258.739L233.211+281.588C233.211+308.053+229.647+328.415+222.518+342.672C209.432+368.649+184.725+381.637+148.397+381.637C112.069+381.637+87.4103+368.649+74.422+342.672C67.2931+328.415+63.7287+308.053+63.7287+281.588L63.7287+160.739L93.3185+160.739Z"
        opacity="1"
        fill="#14d2d5"
      />
      <path
        d="M275.545+160.739L301.912+160.739L301.912+375.924L275.545+375.924L275.545+160.739Z"
        opacity="1"
        fill="#000000"
      />
      <path
        d="M361.678+334.176C361.678+341.793+364.461+347.799+370.027+352.194C375.594+356.588+382.186+358.786+389.803+358.786C399.08+358.786+408.065+356.637+416.756+352.34C431.404+345.211+438.729+333.542+438.729+317.331L438.729+296.09C435.506+298.141+431.356+299.85+426.277+301.217C421.199+302.584+416.219+303.561+411.336+304.147L395.369+306.198C385.799+307.467+378.621+309.469+373.836+312.204C365.731+316.793+361.678+324.118+361.678+334.176ZM425.545+280.856C431.6+280.075+435.652+277.536+437.703+273.239C438.875+270.895+439.461+267.526+439.461+263.131C439.461+254.147+436.263+247.628+429.866+243.576C423.47+239.523+414.315+237.497+402.401+237.497C388.631+237.497+378.865+241.208+373.104+248.629C369.881+252.731+367.781+258.834+366.805+266.94L342.195+266.94C342.684+247.604+348.958+234.152+361.019+226.584C373.079+219.015+387.069+215.231+402.986+215.231C421.444+215.231+436.434+218.747+447.957+225.778C459.383+232.809+465.096+243.747+465.096+258.59L465.096+348.971C465.096+351.706+465.657+353.903+466.78+355.563C467.903+357.223+470.272+358.053+473.885+358.053C475.057+358.053+476.375+357.98+477.84+357.834C479.305+357.687+480.867+357.467+482.527+357.174L482.527+376.657C478.426+377.829+475.301+378.561+473.152+378.854C471.004+379.147+468.074+379.293+464.363+379.293C455.281+379.293+448.69+376.071+444.588+369.626C442.44+366.208+440.926+361.374+440.047+355.124C434.676+362.155+426.961+368.258+416.902+373.434C406.844+378.61+395.76+381.198+383.651+381.198C369.1+381.198+357.21+376.779+347.982+367.941C338.753+359.103+334.139+348.043+334.139+334.762C334.139+320.211+338.68+308.932+347.762+300.924C356.844+292.917+368.758+287.985+383.504+286.129L425.545+280.856Z"
        opacity="1"
        fill="#000000"
      />
      <path
        d="M506.258+160.006L531.893+160.006L531.893+238.083C537.654+230.563+544.539+224.826+552.547+220.871C560.555+216.916+569.246+214.938+578.621+214.938C598.152+214.938+613.997+221.652+626.155+235.08C638.314+248.507+644.393+268.307+644.393+294.479C644.393+319.284+638.387+339.889+626.375+356.295C614.363+372.702+597.713+380.905+576.424+380.905C564.51+380.905+554.451+378.024+546.248+372.262C541.365+368.844+536.141+363.376+530.574+355.856L530.574+375.924L506.258+375.924L506.258+160.006ZM574.813+357.614C589.07+357.614+599.739+351.95+606.819+340.622C613.9+329.293+617.44+314.352+617.44+295.797C617.44+279.293+613.9+265.622+606.819+254.782C599.739+243.942+589.315+238.522+575.545+238.522C563.533+238.522+553.011+242.965+543.978+251.852C534.944+260.739+530.428+275.387+530.428+295.797C530.428+310.543+532.283+322.506+535.994+331.686C542.928+348.971+555.867+357.614+574.813+357.614Z"
        opacity="1"
        fill="#000000"
      />
      <path
        d="M690.828+326.706C691.61+335.495+693.807+342.233+697.42+346.92C704.061+355.417+715.584+359.665+731.99+359.665C741.756+359.665+750.35+357.541+757.772+353.292C765.194+349.044+768.904+342.477+768.904+333.59C768.904+326.852+765.926+321.725+759.969+318.209C756.16+316.061+748.641+313.571+737.41+310.739L716.463+305.465C703.084+302.145+693.221+298.434+686.873+294.333C675.545+287.204+669.881+277.34+669.881+264.743C669.881+249.899+675.228+237.887+685.921+228.708C696.614+219.528+710.994+214.938+729.061+214.938C752.694+214.938+769.735+221.872+780.184+235.739C786.727+244.528+789.901+254.001+789.705+264.157L764.803+264.157C764.315+258.2+762.215+252.78+758.504+247.897C752.449+240.963+741.951+237.497+727.01+237.497C717.049+237.497+709.505+239.401+704.378+243.209C699.251+247.018+696.688+252.047+696.688+258.297C696.688+265.133+700.057+270.602+706.795+274.704C710.701+277.145+716.463+279.293+724.08+281.149L741.512+285.397C760.457+289.987+773.152+294.43+779.598+298.727C789.852+305.465+794.979+316.061+794.979+330.514C794.979+344.479+789.681+356.54+779.085+366.696C768.489+376.852+752.352+381.93+730.672+381.93C707.332+381.93+690.804+376.632+681.087+366.037C671.37+355.441+666.17+342.331+665.486+326.706L690.828+326.706Z"
        opacity="1"
        fill="#000000"
      />
      <path
        d="M202.595+221.647C202.595+213.427+209.259+206.763+217.479+206.763C225.699+206.763+232.363+213.427+232.363+221.647C232.363+229.867+225.699+236.53+217.479+236.53C209.259+236.53+202.595+229.867+202.595+221.647Z"
        opacity="1"
        fill="#14d2d5"
      />
    </g>
    <g id="Dark" vectornator:layerName="Dark">
      <path
        d="M38.3185+9.73879L38.3185+142.747C38.3185+158.372+41.2482+171.36+47.1076+181.711C55.799+197.336+70.4474+205.149+91.0529+205.149C115.76+205.149+132.557+196.702+141.444+179.807C146.229+170.627+148.621+158.274+148.621+142.747L148.621+107.739L178.211+107.739L178.211+130.588C178.211+157.053+174.647+177.415+167.518+191.672C154.432+217.649+129.725+230.637+93.3966+230.637C57.0685+230.637+32.4103+217.649+19.422+191.672C12.2931+177.415+8.72866+157.053+8.72866+130.588L8.72866+9.73879L38.3185+9.73879Z"
        opacity="1"
        fill="#14d2d5"
      />
      <path
        d="M220.545+9.73879L246.912+9.73879L246.912+224.924L220.545+224.924L220.545+9.73879Z"
        opacity="1"
        fill="#ffffff"
      />
      <path
        d="M306.678+183.176C306.678+190.793+309.461+196.799+315.027+201.194C320.594+205.588+327.186+207.786+334.803+207.786C344.08+207.786+353.065+205.637+361.756+201.34C376.404+194.211+383.729+182.542+383.729+166.331L383.729+145.09C380.506+147.141+376.356+148.85+371.277+150.217C366.199+151.584+361.219+152.561+356.336+153.147L340.369+155.198C330.799+156.467+323.621+158.469+318.836+161.204C310.731+165.793+306.678+173.118+306.678+183.176ZM370.545+129.856C376.6+129.075+380.652+126.536+382.703+122.239C383.875+119.895+384.461+116.526+384.461+112.131C384.461+103.147+381.263+96.6284+374.866+92.5757C368.47+88.523+359.315+86.4966+347.401+86.4966C333.631+86.4966+323.865+90.2075+318.104+97.6294C314.881+101.731+312.781+107.834+311.805+115.94L287.195+115.94C287.684+96.604+293.958+83.1519+306.019+75.5835C318.079+68.0152+332.069+64.231+347.986+64.231C366.444+64.231+381.434+67.7466+392.957+74.7778C404.383+81.8091+410.096+92.7466+410.096+107.59L410.096+197.971C410.096+200.706+410.657+202.903+411.78+204.563C412.903+206.223+415.272+207.053+418.885+207.053C420.057+207.053+421.375+206.98+422.84+206.834C424.305+206.687+425.867+206.467+427.527+206.174L427.527+225.657C423.426+226.829+420.301+227.561+418.152+227.854C416.004+228.147+413.074+228.293+409.363+228.293C400.281+228.293+393.69+225.071+389.588+218.626C387.44+215.208+385.926+210.374+385.047+204.124C379.676+211.155+371.961+217.258+361.902+222.434C351.844+227.61+340.76+230.198+328.651+230.198C314.1+230.198+302.21+225.779+292.982+216.941C283.753+208.103+279.139+197.043+279.139+183.762C279.139+169.211+283.68+157.932+292.762+149.924C301.844+141.917+313.758+136.985+328.504+135.129L370.545+129.856Z"
        opacity="1"
        fill="#ffffff"
      />
      <path
        d="M452.258+9.00636L477.893+9.00636L477.893+87.0825C483.654+79.563+490.539+73.8257+498.547+69.8706C506.555+65.9155+515.246+63.938+524.621+63.938C544.152+63.938+559.997+70.6519+572.155+84.0796C584.314+97.5073+589.393+117.307+589.393+143.479C589.393+168.284+584.387+188.889+572.375+205.295C560.363+221.702+543.713+229.905+522.424+229.905C510.51+229.905+500.451+227.024+492.248+221.262C487.365+217.844+482.141+212.376+476.574+204.856L476.574+224.924L452.258+224.924L452.258+9.00636ZM519.813+206.614C534.07+206.614+544.739+200.95+551.819+189.622C558.9+178.293+562.44+163.352+562.44+144.797C562.44+128.293+558.9+114.622+551.819+103.782C544.739+92.9419+534.315+87.522+520.545+87.522C508.533+87.522+498.011+91.9653+488.978+100.852C479.944+109.739+475.428+124.387+475.428+144.797C475.428+159.543+477.283+171.506+480.994+180.686C487.928+197.971+500.867+206.614+519.813+206.614Z"
        opacity="1"
        fill="#ffffff"
      />
      <path
        d="M635.828+175.706C636.61+184.495+638.807+191.233+642.42+195.92C649.061+204.417+660.584+208.665+676.99+208.665C686.756+208.665+695.35+206.541+702.772+202.292C710.194+198.044+713.904+191.477+713.904+182.59C713.904+175.852+710.926+170.725+704.969+167.209C701.16+165.061+693.641+162.571+682.41+159.739L661.463+154.465C648.084+151.145+638.221+147.434+631.873+143.333C620.545+136.204+614.881+126.34+614.881+113.743C614.881+98.8989+620.228+86.8872+630.921+77.7075C641.614+68.5278+655.994+63.938+674.061+63.938C697.694+63.938+714.735+70.8716+725.184+84.7388C731.727+93.5278+734.901+103.001+734.705+113.157L709.803+113.157C709.315+107.2+707.215+101.78+703.504+96.897C697.449+89.9634+686.951+86.4966+672.01+86.4966C662.049+86.4966+654.505+88.4009+649.378+92.2095C644.251+96.0181+641.688+101.047+641.688+107.297C641.688+114.133+645.057+119.602+651.795+123.704C655.701+126.145+661.463+128.293+669.08+130.149L686.512+134.397C705.457+138.987+718.152+143.43+724.598+147.727C734.852+154.465+739.979+165.061+739.979+179.514C739.979+193.479+734.681+205.54+724.085+215.696C713.489+225.852+697.352+230.93+675.672+230.93C652.332+230.93+635.804+225.632+626.087+215.037C616.37+204.441+611.17+191.331+610.486+175.706L635.828+175.706Z"
        opacity="1"
        fill="#ffffff"
      />
      <path
        d="M147.595+70.6467C147.595+62.4266+154.259+55.7629+162.479+55.7629C170.699+55.7629+177.363+62.4266+177.363+70.6467C177.363+78.8667+170.699+85.5304+162.479+85.5304C154.259+85.5304+147.595+78.8667+147.595+70.6467Z"
        opacity="1"
        fill="#14d2d5"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'logo-dark',
};
</script>

<style></style>
